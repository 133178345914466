<!-- 市场走势 -->
<template>
  <div style="color: #fff" id="LotteryPrize">
    <van-nav-bar :title="$t('m_marketMovements.title')"> </van-nav-bar>
    <div class="game-tab-list">
      <ul class="game-tab">
        <li class="active">VND</li>
        <li class="">ETH</li>
        <li class="">USDT</li>
        <li class="">BTC</li>
        <li class="">ADA</li>
        <li class="">AVAX</li>
        <li class="">LTC</li>
      </ul>
    </div>
    <div class="echarts-wrapper">
      <div id="myChart" :style="{ width: '100%', height: '300px' }"></div>
      <div id="myChart1" :style="{ width: '100%', height: '300px' }"></div>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
var demoList = [
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "18",
    period_code: "9,6,3",
    end_time: 1637738340,
    official_time: 1637738400,
    lottery_number: "066481",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "11",
    period_code: "9,0,2",
    end_time: 1637738040,
    official_time: 1637738100,
    lottery_number: "066480",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "20",
    period_code: "9,7,4",
    end_time: 1637737740,
    official_time: 1637737800,
    lottery_number: "066479",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "14",
    period_code: "5,3,6",
    end_time: 1637737440,
    official_time: 1637737500,
    lottery_number: "066478",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "16",
    period_code: "2,9,5",
    end_time: 1637737140,
    official_time: 1637737200,
    lottery_number: "066477",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "13",
    period_code: "6,2,5",
    end_time: 1637736840,
    official_time: 1637736900,
    lottery_number: "066476",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "15",
    period_code: "4,3,8",
    end_time: 1637736540,
    official_time: 1637736600,
    lottery_number: "066475",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "18",
    period_code: "5,8,5",
    end_time: 1637736240,
    official_time: 1637736300,
    lottery_number: "066474",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "9",
    period_code: "1,4,4",
    end_time: 1637735940,
    official_time: 1637736000,
    lottery_number: "066473",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "7",
    period_code: "2,3,2",
    end_time: 1637735640,
    official_time: 1637735700,
    lottery_number: "066472",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "12",
    period_code: "0,7,5",
    end_time: 1637735340,
    official_time: 1637735400,
    lottery_number: "066471",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "10",
    period_code: "3,6,1",
    end_time: 1637735040,
    official_time: 1637735100,
    lottery_number: "066470",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "9",
    period_code: "3,0,6",
    end_time: 1637734740,
    official_time: 1637734800,
    lottery_number: "066469",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "14",
    period_code: "8,3,3",
    end_time: 1637734440,
    official_time: 1637734500,
    lottery_number: "066468",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "21",
    period_code: "5,9,7",
    end_time: 1637734140,
    official_time: 1637734200,
    lottery_number: "066467",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "14",
    period_code: "9,3,2",
    end_time: 1637733840,
    official_time: 1637733900,
    lottery_number: "066466",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "17",
    period_code: "4,4,9",
    end_time: 1637733540,
    official_time: 1637733600,
    lottery_number: "066465",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "16",
    period_code: "6,3,7",
    end_time: 1637733240,
    official_time: 1637733300,
    lottery_number: "066464",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "16",
    period_code: "3,6,7",
    end_time: 1637732940,
    official_time: 1637733000,
    lottery_number: "066463",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "5",
    period_code: "0,4,1",
    end_time: 1637732640,
    official_time: 1637732700,
    lottery_number: "066462",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "8",
    period_code: "4,3,1",
    end_time: 1637732340,
    official_time: 1637732400,
    lottery_number: "066461",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "12",
    period_code: "1,7,4",
    end_time: 1637732040,
    official_time: 1637732100,
    lottery_number: "066460",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "20",
    period_code: "9,4,7",
    end_time: 1637731740,
    official_time: 1637731800,
    lottery_number: "066459",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "11",
    period_code: "3,0,8",
    end_time: 1637731440,
    official_time: 1637731500,
    lottery_number: "066458",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "12",
    period_code: "7,5,0",
    end_time: 1637731140,
    official_time: 1637731200,
    lottery_number: "066457",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "17",
    period_code: "5,8,4",
    end_time: 1637730840,
    official_time: 1637730900,
    lottery_number: "066456",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "10",
    period_code: "5,2,3",
    end_time: 1637730540,
    official_time: 1637730600,
    lottery_number: "066455",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "13",
    period_code: "4,1,8",
    end_time: 1637730240,
    official_time: 1637730300,
    lottery_number: "066454",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "19",
    period_code: "9,2,8",
    end_time: 1637729940,
    official_time: 1637730000,
    lottery_number: "066453",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "10",
    period_code: "0,2,8",
    end_time: 1637729640,
    official_time: 1637729700,
    lottery_number: "066452",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "12",
    period_code: "0,7,5",
    end_time: 1637729340,
    official_time: 1637729400,
    lottery_number: "066451",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "22",
    period_code: "4,9,9",
    end_time: 1637729040,
    official_time: 1637729100,
    lottery_number: "066450",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "9",
    period_code: "0,9,0",
    end_time: 1637728740,
    official_time: 1637728800,
    lottery_number: "066449",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "26",
    period_code: "9,9,8",
    end_time: 1637728440,
    official_time: 1637728500,
    lottery_number: "066448",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "14",
    period_code: "6,4,4",
    end_time: 1637728140,
    official_time: 1637728200,
    lottery_number: "066447",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "8",
    period_code: "0,1,7",
    end_time: 1637727840,
    official_time: 1637727900,
    lottery_number: "066446",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "15",
    period_code: "8,0,7",
    end_time: 1637727540,
    official_time: 1637727600,
    lottery_number: "066445",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "13",
    period_code: "1,5,7",
    end_time: 1637727240,
    official_time: 1637727300,
    lottery_number: "066444",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "13",
    period_code: "4,9,0",
    end_time: 1637726940,
    official_time: 1637727000,
    lottery_number: "066443",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "19",
    period_code: "7,4,8",
    end_time: 1637726640,
    official_time: 1637726700,
    lottery_number: "066442",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "19",
    period_code: "5,8,6",
    end_time: 1637726340,
    official_time: 1637726400,
    lottery_number: "066441",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "15",
    period_code: "8,3,4",
    end_time: 1637726040,
    official_time: 1637726100,
    lottery_number: "066440",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "7",
    period_code: "3,1,3",
    end_time: 1637725740,
    official_time: 1637725800,
    lottery_number: "066439",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "13",
    period_code: "6,0,7",
    end_time: 1637725440,
    official_time: 1637725500,
    lottery_number: "066438",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "20",
    period_code: "8,8,4",
    end_time: 1637725140,
    official_time: 1637725200,
    lottery_number: "066437",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "13",
    period_code: "0,9,4",
    end_time: 1637724840,
    official_time: 1637724900,
    lottery_number: "066436",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "9",
    period_code: "9,0,0",
    end_time: 1637724540,
    official_time: 1637724600,
    lottery_number: "066435",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "5",
    period_code: "0,1,4",
    end_time: 1637724240,
    official_time: 1637724300,
    lottery_number: "066434",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "13",
    period_code: "5,0,8",
    end_time: 1637723940,
    official_time: 1637724000,
    lottery_number: "066433",
    sx: null,
  },
  {
    lottery_type: 4,
    pid: 1,
    lottery_name: "台湾幸运28",
    now_time: 1637738441,
    period_result: "19",
    period_code: "5,6,8",
    end_time: 1637723640,
    official_time: 1637723700,
    lottery_number: "066432",
    sx: null,
  },
];
import FooterMenu from "@/components/mc/FooterMenu";
import * as echarts from "echarts";
export default {
  name: "",
  components: { FooterMenu },
  data() {
    return {};
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log(this);
    this.drawLine();
    this.drawLine1();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById("myChart"));
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          data: ["日K", "MA5", "MA10", "MA20", "MA30"],
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "15%",
        },
        xAxis: {
          type: "category",
          data: ["2013/1/24"],
          scale: true,
          boundaryGap: false,
          axisLine: {
            onZero: false,
          },
          splitLine: {
            show: false,
          },
          splitNumber: 10,
          min: "dataMin",
          max: "dataMax",
        },
        yAxis: {
          scale: true,
          splitArea: {
            show: true,
          },
        },
        series: [
          {
            name: "日K",
            type: "candlestick",
            data: [],
            itemStyle: {
              color0: "rgba(255, 255, 255, 0)",
            },
          },
        ],
      };
      let xData = [];
      let yData = [];
      demoList.forEach((item) => {
        xData.push(item.lottery_number);
        let list = [];
        item.period_code.split(",").forEach((childItem) => {
          list.push(parseInt(childItem));
        });
        list.splice(1, 0, parseInt(item.period_result));
        /* list.push(parseInt(childItem)); */
        yData.push(list);
      });
      option.series[0].data = yData;
      option.xAxis.data = xData;
      console.log(yData);
      console.log(xData);
      // 绘制图表
      myChart.setOption(option);
    },
		drawLine1() {
			let myChart1 = echarts.init(document.getElementById("myChart1"));
      let xAxisData = [];
      let data1 = [];
      let data3 = [];
      for (let i = 0; i < 10; i++) {
        xAxisData.push('Class' + i);
        data1.push(+(Math.random() * 20).toFixed(2));
        data3.push(+(Math.random() + -13).toFixed(2));
      }
      var emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.3)'
        }
      };
      let option = {
        legend: {
          data: ['bar', 'bar3'],
          left: '10%'
        },
        tooltip: {},
        xAxis: {
          data: xAxisData,
          name: 'X Axis',
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {},
        grid: {
          bottom: 100
        },
        series: [
          {
            name: 'bar',
            type: 'bar',
            stack: 'one',
            emphasis: emphasisStyle,
            data: data1
          },
          {
            name: 'bar3',
            type: 'bar',
            stack: 'two',
            emphasis: emphasisStyle,
            data: data3
          }
        ]
      };
			let xData = [];
			let yData = [];
			demoList.forEach((item) => {
			  xData.push(item.lottery_number);
			  let list = [];
			  item.period_code.split(",").forEach((childItem) => {
			    list.push(parseInt(childItem));
			  });
			  list.splice(1, 0, parseInt(item.period_result));
			  /* list.push(parseInt(childItem)); */
			  yData.push(list);
			});
			option.series[0].data = yData;
			option.xAxis.data = xData;
			console.log(yData);
			console.log(xData);
			console.log(option);
			// 绘制图表
			myChart1.setOption(option);
    },
	},
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";
.van-nav-bar {
  background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important;
}

/deep/.van-nav-bar__title {
  color: #38373b !important;
}
/deep/.van-nav-bar .van-icon {
  color: #38373b !important;
}
body,html{
	background: #222 !important;
}
#LotteryPrize .game-tab-list {
  overflow-x: auto;
  background-color: #373430;

  .game-tab {
    height: 0.88rem;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    li {
      display: inline-block;
      min-width: 2.6rem;
      line-height: 0.88rem;
      text-align: center;
      letter-spacing: 0.04rem;
      color: #ffffff;
      font-size: 0.36rem;
      padding: 0 0.4rem;
      font-weight: normal;
      position: relative;

      &.active {
        color: #eecb77;
      }

      &.active::after {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0);
      }
    }

    li::after {
      display: block;
      content: " ";
      width: 0.67rem;
      height: 0.08rem;
      background-image: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%);
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -0.4rem;
      -webkit-transition: all 0.55s;
      transition: all 0.55s;
      opacity: 0;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }
}
</style>